.thumbnail-displayer-container{
    display: flex;
    overflow: hidden; 
    margin-top: 1.3rem;
    margin: 1rem auto;
    width: fit-content;
    max-width: 70vh
}

.inner-thumbnail-container{
    display: flex;
    position: relative;
    gap: 1rem;
    transition: all 0.5s;
    align-items: center;
    height: calc((70vh - 10rem) / 9 + 2rem);
    padding: 0 0.3rem;
} 

.thumbnail-img-container{
    height: calc((70vh - 10rem) / 9);
    width: calc((70vh - 10rem) / 9);
    filter: brightness(0.8) grayscale(0.5);
    border-radius: 5%;
    transition: all 0.2s;
    overflow: hidden;
    cursor: pointer;
}

.selected-thumbnail{
    filter: none;
    height: calc((70vh - 9rem) / 9 + 1rem);
    width: calc((70vh - 9rem) / 9 + 1rem);
    border-radius: 5%;
}

.thumbnail-img{
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.thumbnail-img-hidden{
    height: 100%;
    width: 100%;
    object-fit: cover;
    filter: grayscale(1) brightness(0.7);
}

@media (max-width: 768px) {
    .thumbnail-displayer-container{
        display: none
    }
    
}