.banner-container{
    width: 100%;
    height: 100vh;
    overflow: hidden;
    margin: 0;
    top: 0;
    left: 0;
}

.banner-inner{
    height: 100%;
}

.background-banner{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
}

.banner-phrase-container{
    height: 100%;
    padding: 0;
    display: flex;
}

.banner-phrase{
    position: relative;
    color: #eee;
    text-align: left;
    font-size: 4.2rem;
    font-weight: 300;
    line-height: 3.6rem;
    letter-spacing: -0.35rem;
    top: 30%;
    left: 10%;
    text-shadow: 0 0 3px black;
}

.banner-info{
    position: relative;
    color: #aaa;
    text-align: justify;
    text-align-last: right;
    font-size: 1rem;
    max-width: 25%;
    font-family: Karla;
    margin: auto 3rem 3rem auto;
    text-shadow: 0 0 3px black;
}

.scroll-down-icon{
    color: #ccc;
    font-size: 2rem;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1000;
    width: 100%;
    padding: 2rem 0;
    animation: 13s ease-in 10s infinite reverse both running move;
}

.scroll-down-icon:hover{
    color: #fff;
}

@keyframes move {
    0%, 1%{
        transform: translateY(0.2rem);
        color: #da9401;
    }
    2%{
        transform: translateY(-0.2rem);
        color: #7ada01;
    }
    3%{
        transform: translateY(0.2rem);
        color: #7a01da;
    }
    4%{
        transform: translateY(-0.2rem);
        color: #017ada;
    }
    5%{
        transform: translateY(0.2rem);
        color: #fff;
    }
    6%{
        transform: translateY(-0.2rem);
    }
    7%, 100%{
        transform: translateY(0.2rem);
    }
}

@media (max-width: 768px) {

    .background-banner{
        position: absolute;
        width: 100%;
        height: 85%;
        left: 0;
        object-fit: cover;
    }

    .banner-phrase-container{
        padding: 3rem;
        display: flex;
        flex-direction: column;
    }

    .banner-phrase{
        position: relative;
        font-size: 2.4rem;
        max-width: 100%;
        line-height: 2.5rem;
        letter-spacing: -0.1rem;
        text-shadow: 0 0 3px #222;
        top: 30%;
        left: 0;
    }

    .banner-info{
        display: none;
    }

    .scroll-down-icon{
        display: none;
    }

}