.notfound-container{
    height: 100vh;
    background-color: #171718;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.notfound-msg{
    color: #eee;
    font-size: 2rem;
    margin-top: 20%;
}

.notfound-btn{
    background-color: #eee;
    border: none;
    padding: 1rem;
    margin-top: 3rem;
    border-radius: 1rem;
}

.notfound-btn a{
    color: #222;
    text-decoration: none;
    font-weight: 600;
}

