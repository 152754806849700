.game-outer-container{
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  backdrop-filter: brightness(0);
  z-index: 10000;
}

.game-container{
  margin: auto;
  width: 70vh;
  height: 100%;
  display: flex;
  align-items: center;
}

@media (max-width: 768px) {
  .game-outer-container{
    display: none;

  }
  .game-container{
    display: none;
  }
}