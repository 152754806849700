.section-divider{
    height: 0.33rem;
    width: 45vh;
    border-radius: 0.5rem;
    background-color: #da9401;
    margin: 3rem auto
}

@media (max-width: 768px) {
    .section-divider{
        width: 45%;
        margin: 1.5rem auto
    }
}