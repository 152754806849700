.modal-container{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #0008;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    padding: 3%;
    backdrop-filter: grayscale(70%) blur(0.2rem) b;
}

.modal-card-container{
    width: fit-content;
    max-width: 80vw;
    height: fit-content;
    display: flex;
    justify-content: center;
    box-shadow: 0 0 5px #000;
    border-radius: 0.5rem;
}

.modal-img{
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
    height: 100%;
    max-height: 85vh;
    min-height: 80vh;
}

.modal-img-only{
    border-radius: 0.5rem;
    max-height: 85vh;
    min-height: 70vh;
}

.item-info-container{
    overflow: auto;
    height: 100%;
    padding: 0.5rem 1rem;
}

.item-info-container::-webkit-scrollbar {
    width: 0.5rem;
    z-index: 10;
}
  
.item-info-container::-webkit-scrollbar-track {
    background-color: #171717
}
  
.item-info-container::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #444;
    box-shadow: inset 0 0 3px #000;
}
  
.item-info-container::-webkit-scrollbar-thumb:hover {
    background-color: #666;
}

.modal-text-container{
    background-color: #000;
    margin: 0;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    width: 30rem;
}

.modal-title{
    color: #fff;
    font-size: 2rem;
    text-align: left;
    padding: 0 7%;
    font-family: Karla;
    letter-spacing: -0.1rem;
}

.modal-text{
    color: #fff;
    margin: 0;
    padding: 7%;
    text-align: left;
    font-family: 'Roboto';
    font-weight: 400;
}

.modal-close-btn{
    background: none;
    margin-left: auto;
    border: none;
}

.modal-close-btn-img-only{
    position: absolute;
    right: 0;
    background: none;
    border: none;
    color: #777;
    margin: 0.8rem;
    z-index: 7000;
}

.modal-close-btn-mobile{
    display: none;
}

.modal-close-icon{
    color: #555;
    font-size: 2.5rem;
}

.modal-close-icon:hover{
    color: #fff;
}

#hidden-content{
    filter: grayscale(1);
}

.modal-controller-prev, .modal-controller-next{
    width: 100%;
    height: 40%;
    background-color: transparent;
    border: none;
    z-index: 6000;
}

.modal-controller-prev:hover .modal-prev-icon, .modal-controller-next:hover .modal-next-icon{
    color: #fff;
}

.modal-controller-prev{
    margin-left: calc((100vw - 71vh) / -2);
}

.modal-controller-next{
    margin-right: calc((100vw - 71vh) / -2);
}

.modal-prev-icon, .modal-next-icon{
    font-size: 2rem;
    color: #777;
    margin: 0 2rem;
}

.modal-prev-icon{
    float: right;
}

.modal-next-icon{
    float: left;
}

@media (max-width: 768px) {
    .modal-container{
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        min-height: 100vh;
        height: 100vh;
        background-color: #0008;
        z-index: 1000;
        padding: 1.5rem;
        backdrop-filter: grayscale(80%) blur(0.08rem);
        overflow: auto;
    }
    
    .modal-card-container{
        width: calc(100vw - 3rem);
        max-width: none;
        height: fit-content;
        display: flex;
        flex-direction: column;
        justify-content: center;
        box-shadow: 0 0 5px #000;
        border-radius: 0.5rem;
        margin: auto 0;
    }
    
    .modal-img{
        border-top-right-radius: 0.5rem;
        border-bottom-left-radius: 0;
        width: calc(100vw - 3rem);
        min-height: 0;
        height:fit-content
    }
    
    .modal-img-only{
        border-radius: 0.5rem;
        width: calc(100vw - 3rem);
        min-height: 0;
        height:fit-content
    }
    
    .modal-text-container{
        background-color: #000d;
        margin: 0;
        display: flex;
        flex-direction: column;
        padding: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0.5rem;
        border-bottom-left-radius: 0.5rem;
        width: calc(100vw - 3rem);
        max-height: none;
        height: fit-content;
    }

    .item-info-container{
        overflow: auto;
        height: 100%;
        padding: 1.5rem;
    }
    
    .item-info-container::-webkit-scrollbar {
        width: 0.5rem;
        z-index: 10;
    }
      
    .item-info-container::-webkit-scrollbar-track {
        background-color: #171717
    }
      
    .item-info-container::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #444;
        box-shadow: inset 0 0 3px #000;
    }
      
    .item-info-container::-webkit-scrollbar-thumb:hover {
        background-color: #666;
    }
    
    .modal-title{
        color: #fff;
        font-size: 1.5rem;
        text-align: left;
        padding: 0;
        font-family: Karla;
        letter-spacing: -0.1rem;
        margin-bottom: 1.5rem;
    }
    
    .modal-text{
        color: #fff;
        margin: 0;
        padding: 0;
        text-align: left;
        font-family: 'Roboto';
        font-weight: 400;
        font-size: 0.8rem;
    }
    
    .modal-close-btn-mobile{
        background-color: #0003;
        margin-left: auto;
        border: none;
        border-radius: 0.5rem;
        position: absolute;
        right: 0;
        z-index: 5000;
        display: block;
        padding: 0;
    }

    .modal-close-icon{
        color: #aaa;
        font-size: 2.5rem;
    }

    .modal-close-btn{
        display: none;
    }
    
    #hidden-content{
        filter: grayscale(1);
    }

    .modal-controller-prev, .modal-controller-next{
        display: none;
    }
    
    .modal-prev-icon{
        float: right;
    }
    
    .modal-next-icon{
        float: left;
    }
}