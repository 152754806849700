.edit-modal-container{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #0008;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    padding: 3%;
}

.edit-item-info-container{
    overflow: auto;
    height: 100%;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
}


.edit-item-info-container{
    overflow: auto;
    height: 100%;
    padding: 0.5rem 1rem;
}


.edit-item-info-container::-webkit-scrollbar {
    width: 0.5rem;
    z-index: 10;
}
  
.edit-item-info-container::-webkit-scrollbar-track {
    background-color: #171717
}
  
.edit-item-info-container::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #444;
    box-shadow: inset 0 0 3px #000;
}
  
.edit-item-info-container::-webkit-scrollbar-thumb:hover {
    background-color: #666;
}


.edit-modal-text-container{
    background-color: #222;
    margin: 0;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    width: 30rem;
}

.edit-item-form{
    display: flex;
    flex-direction: column;
    min-height: 80%;
}

.edit-input-label{
    color: #fff;
    align-self: flex-start;
    margin-bottom: 0.2rem;
    font-weight: 500;
}

.edit-mode-alert{
    color: #da9401;
    font-weight: 600;
    font-size: 1.5rem;
}

#edit-description-input, #edit-title-input, #edit-img-input{
    background-color: transparent;
    border: 1px solid #444;
    color: #fff;
    padding: 0.3rem;
    border-radius: 0.5rem;
    margin-bottom: 1.5rem;
}


#edit-description-input:focus, #edit-title-input:focus, #edit-img-input:focus{
    outline: 1px solid #da9401;
    border: none;
    background-color: transparent;
    color: #fff;
}

.edit-submit, .hide-content-btn, .show-content-btn{
    color: #000;
    border: none;
    border-radius: 0.5rem;
    background-color: #da9401;
    height: 2rem;
    font-weight: 600;
    margin-top: auto;
    width: fit-content;
    padding: 0 1rem;
    margin: auto auto 0;
}


.edit-submit{
    background-color: #da9401;
}

.edit-submit:hover{
    background-color: #fab421;
}

.hide-content-btn{
    background-color: #444;
}

.show-content-btn{
    background-color: rgb(0, 100, 30);
}

.hidden-show-text{
    color: #fff;
    font-size: 0.8rem;
    margin-bottom: 0.3rem;
}



@media (max-width: 768px) {
    .edit-modal-container{
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: #0008;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1000;
        padding: 3%;
    }
    
    .edit-item-info-container{
        overflow: auto;
        height: 100%;
        padding: 0.5rem 1rem;
        border-radius: 0.5rem;
    }

    .edit-item-info-container{
        overflow: auto;
        height: 100%;
        padding: 0.5rem 1rem;
    }
    
    
    .edit-item-info-container::-webkit-scrollbar {
        width: 0.5rem;
        z-index: 10;
    }
      
    .edit-item-info-container::-webkit-scrollbar-track {
        background-color: #171717
    }
      
    .edit-item-info-container::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #444;
        box-shadow: inset 0 0 3px #000;
    }
      
    .edit-item-info-container::-webkit-scrollbar-thumb:hover {
        background-color: #666;
    }
    
    
    .edit-modal-text-container{
        background-color: #222;
        margin: 0;
        display: flex;
        flex-direction: column;
        padding: 1rem;
        border-top-right-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
        width: calc(100vw - 3rem);
        max-width: none;
        min-height: calc(100vw - 3rem);
    }
    
    .edit-item-form{
        display: flex;
        flex-direction: column;
        min-height: 80%;
    }
    
    .edit-input-label{
        color: #fff;
        align-self: flex-start;
        margin-bottom: 0.2rem;
        font-weight: 500;
    }
    
    .edit-mode-alert{
        color: #da9401;
        font-weight: 600;
        font-size: 1.5rem;
    }
    
    #edit-description-input, #edit-title-input, #edit-img-input{
        background-color: transparent;
        border: 1px solid #444;
        color: #fff;
        padding: 0.3rem;
        border-radius: 0.5rem;
        margin-bottom: 1.5rem;
        font-size: 0.8rem;
    }
    
    
    #edit-description-input:focus, #edit-title-input:focus, #edit-img-input:focus{
        outline: 1px solid #da9401;
        border: none;
        background-color: transparent;
        color: #fff;
    }
    
    .edit-submit, .hide-content-btn, .show-content-btn{
        color: #000;
        border: none;
        border-radius: 0.5rem;
        background-color: #da9401;
        height: 2rem;
        font-weight: 600;
        margin-top: auto;
        width: fit-content;
        padding: 0 1rem;
        margin: auto auto 0;
    }
    
    
    .edit-submit{
        background-color: #da9401;
    }
    
    .edit-submit:hover{
        background-color: #fab421;
    }
    
    .hide-content-btn{
        background-color: #444;
    }
    
    .show-content-btn{
        background-color: rgb(0, 100, 30);
    }
    
    .hidden-show-text{
        color: #fff;
        font-size: 0.8rem;
        margin-bottom: 0.3rem;
    }

}