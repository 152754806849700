.content-container{
    padding: 1rem 0 3rem;
}

.middle-section{
    display: flex;
    justify-content: center;
}

.order-tag{
    color: #eee;
    margin: 0 0 0.5rem;
}

.order-tag span{
    background-color: #777;
    padding: 0.2rem;
    border-radius: 0.5rem;
    font-weight: 500;
}

.video-item{
    align-self: center;
}

.photography-img-container{
    aspect-ratio: 1/1;
    height: 70vh;
    display: flex;
}

.content-img-container{
    aspect-ratio: 1/1;
    height: 70vh;
    display: flex;
    background-size: cover;
    border-radius: 0.1rem;
    overflow: hidden;
}

.content-video-container{
    aspect-ratio: 1/1;
    height: 70vh;
    display: flex;
    background-size: cover;
    border-radius: 0.1rem;
    overflow: hidden;
}

.content-img{
    display: block;
    width: 100%;
    object-fit: cover;
    cursor: pointer;
    border-radius: 0.1rem;
}

.content-video{
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
    border-radius: 0.1rem;
}

.content-img-hidden{
    filter: grayscale(1) brightness(0.7);
    display: block;
    width: 100%;
    object-fit: cover;
    cursor: pointer;
    border-radius: 0.5rem;
    overflow: hidden;
}

.controller-prev, .controller-next{
    background-color: transparent;
    border: none;
}

.controller-prev{
    width: calc((100vw - 71vh) / 2);
    margin-left: calc((100vw - 71vh) / -2);
}

.controller-next{
    width: calc((100vw - 71vh) / 2);
    margin-right: calc((100vw - 71vh) / -2);
}

.prev-icon, .next-icon{
    font-size: 2rem;
    color: #777;
    margin: 0 2rem;
}

.prev-icon{
    float: right;
}

.next-icon{
    float: left;
}

.icon-invisible{
    color: transparent;
}

.controller-prev:hover .prev-icon, .controller-next:hover .next-icon{
    color: #fff;
}

.fullscreen-icon{
    width: 2rem;
    height: 2rem;
}

.full-screen-icon-container{
    display: none;
    margin: auto -2.5rem 0.5rem;
    cursor: pointer;
    color: #eee;
}

.photography-img-container:hover .full-screen-icon-container{
    display: block;
}

@media (max-width: 768px) {

     .content-container{
        padding: 1rem 0;
        height: fit-content;
    }
    
    .photography-img-container{
        width: 100%;
        aspect-ratio: 1/1;
        display: flex;
        height: fit-content;
        margin-bottom: 1rem;
    }

    .content-img{
        object-fit: contain;
        border-radius: 0.6rem;
    }
    
    .content-img-container, .content-video-container{
        width: 100%;
        height: calc(100vw + 3rem);
        display: flex;
        background-size: cover;
        border-radius: 0.6rem;
        overflow: hidden;
        background-color: #5551;
    }
    
    .content-img-hidden{
        filter: grayscale(1) brightness(0.7);
        display: block;
        width: 100%;
        object-fit: cover;
        cursor: pointer;
        border-radius: 0.5rem;
        overflow: hidden;
    }
    
    .controller-prev, .controller-next{
        position: absolute;
        border: none;
        color: #fff;
        margin-top: calc((100vw + 3rem) / 2 - 1.25rem);
        z-index: 1000;
    }
    
    .controller-prev{
        width: fit-content;
        margin-left: 0;
        left: 1.2rem;
    }
    
    .controller-next{
        width: fit-content;
        margin-right: 0;
        right: 1.2rem;
    }
    
    .prev-icon, .next-icon{
        font-size: 2.2rem;
        color: #fff;
        background-color: #8885;
        padding: 0.3rem;
        border-radius: 50%;
        margin: 0;
    }
    
    .prev-icon{
        float: right;
    }
    
    .next-icon{
        float: left;
    }
    
    .icon-invisible{
        color: transparent;
    }
    
    .fullscreen-icon{
        display: none;
    }
    
    .full-screen-icon-container{
        display: none;
        margin: auto -2.5rem 0.5rem;
        cursor: pointer;
        color: #eee;
    }
    
    .photography-img-container:hover .full-screen-icon-container{
        display: block;
    }
}