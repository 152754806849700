.about-container{
    background-color: #171718;
    width: 70vh;
    margin: 5rem auto 2rem ;
}

.row{
    margin-bottom: 1rem;
    width: 70vh;
}

.col-6, .col-12{
    min-height: 35vh;
    background-color: #171718;
    border: none;
    border-radius: 0;
    margin: 0;
    padding: 0.5rem;
}

.card{
    width: 100%;
    height: 100%;
    background-color: #2b2b2c;
    border: none;
    border-radius: 1.5rem;
    margin: 0;
    padding: 5%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: baseline;

}

.mateo-gif-card{
    padding: 0;
}

.card-side{
    width: 100%;
    height: 100%;
}

.card-side>img, .card-side>video{
    width: 100%;
    height: 100%;
}

.about-title{
    color: #f9f9f9;
    font-size: 2.3rem;
    font-weight: 600;
    text-align: left;
}

.about-text{
    color: #f9f9f9;
    text-align:justify;
}

.icons-container{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: fit-content;
    margin: 0 auto;
    justify-content: space-between;
}

.icons-row{
    display: flex;
    justify-content: center;
    gap: 0.6rem;
    margin-top: 0.6rem;
}

.adobe-logo{
    width: 30%;
}

.tech-title{
    color: #f9f9f9;
    font-size: 1.8rem;
    margin: auto auto 0;
    text-align: center;
    background-color: #171718;
    padding: 1.5rem 0.7rem;
    border-radius: 0.9rem;
    width: calc(90% + 1.2rem);
    height: 4.8rem;
}

.landscape-container{
    margin-top: 0.5rem;
    height: 63%;
    width: 100%;
    border-radius: 0.9rem;
    overflow: hidden;
}

.landscape-img{
    height: 100%;
    width: 100%;
    object-fit: cover;
    filter: brightness(1.3);
}

.timelapse-card{
    padding: 0
}

.timelapse-video{
    object-fit: cover;
}

.about-title-row{
    display: flex;
}

.about-edit-button{
    margin-left: auto;
    background-color: transparent;
    border: none;
    color: #ccc;
    font-size: 1.5rem;
}

.about-edit-button:hover{
    color: #fff;
}

.edit-about-form{
    display: flex;
    flex-direction: column;
}

.edit-about-form label{
    color: #fff;
    text-align: left;
    font-size: 1.1rem;
    font-weight: 600;
}

.edit-about-input{
    padding: 0.6rem 1rem;
    margin-bottom: 0.5rem;
    background-color: #111;
    color: #fff;
    box-shadow: none;
    border: none ;
    border-radius: 0.5rem;
}

.edit-about-input:focus{
    outline: 1px solid #eaa411;
}

#edit-about-title-input{
    margin-bottom: 0.5rem;
}

#edit-about-description-input{
    min-height: 10rem;
}

#edit-about-description-input::-webkit-scrollbar {
    width: 0.5rem;
    z-index: 10;
}

#edit-about-description-input::-webkit-scrollbar-track {
    background-color: #171717
}

#edit-about-description-input::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #444;
    box-shadow: inset 0 0 3px #000;
}

#edit-about-description-input::-webkit-scrollbar-thumb:hover {
    background-color: #666;
}

.submit-about-general{
    margin-top: auto;
}

.buttons-container{
    margin-top: 1rem;
}

.submit-about-general, .submit-about-training{
    background-color: #da9401;
    border: none;
    border-radius: 0.5rem;
    font-size: 1.2rem;
    padding: 0.1rem 1rem;
    font-weight: 600;
    letter-spacing: -2px;
    margin: 0 1rem;
}

.cancel-btn{
    color: #da9401;
    margin: 0 1rem;
    background-color: transparent;
    border: 2px solid #da9401;
    border-radius: 0.5rem;
    font-size: 1.2rem;
    padding: 0 1rem;
    font-weight: 600;
    letter-spacing: -2px;
    margin: 0 1rem;
}

.about-btn-container{
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: center;

}

.cv-button{
    background-color: transparent;
    background-color: #da9401;
    border: none;
    border-radius: 0.5rem;
    font-size: 1.6rem;
    padding: 0 1rem;
    font-weight: 600;
    letter-spacing: -2px;
}

.cv-button:hover{
    background-color: #eaa411;
}

.cv-button a{
    color: #000;
    text-decoration: none;
}

.upload-cv-button{
    color: #da9401;
    margin: 0 1rem;
    background-color: transparent;
    border: 2px solid #da9401;
    border-radius: 0.5rem;
    font-size: 1.6rem;
    padding: 0 1rem;
    font-weight: 600;
    letter-spacing: -2px;
}

.upload-cv-form{
    display: flex;
    gap: 1rem;
    margin: 2rem auto;
    width: 100%;
    justify-content: center;
}

.upload-cv-btn{
    background-color: transparent;
    background-color: #da9401;
    border: none;
    border-radius: 0.5rem;
    font-size: 1.3rem;
    padding: 0 1rem;
    font-weight: 600;
    letter-spacing: -2px;
}

@media (max-width: 768px) {

    .about-container{
        background-color: #171718;
        padding: 1rem;
        width: 100%;
        margin: 0 auto 2rem ;
    }
    
    .row{
        margin: 0 0 1rem 0;
        width: calc(100vw - 2rem);
    }
    
    .col-6, .col-12{
        min-height: calc(50vw - 0.5rem);
        background-color: #171718;
        border: none;
        border-radius: 0;
        margin: 0;
        padding: 0.3rem;
    }
    
    .card{
        width: 100%;
        height: 100%;
        background-color: #2b2b2c;
        border: none;
        border-radius: 1.5rem;
        margin: 0;
        padding: 5%;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: baseline;
    
    }
    
    .mateo-gif-card{
        padding: 0;
    }

    .mateo-gif-card > div {
        object-fit: cover;
        height: 100%;
    }
    
    .card-side>img{
        width: 100%;
        height: 100%;
    }
    
    .about-title{
        color: #f9f9f9;
        font-size: 2rem;
        font-weight: 600;
        text-align: left;
    }
    
    .about-text{
        color: #f9f9f9;
        text-align:justify;
        font-size: 0.85rem;
    }
    
    .icons-container{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        width: fit-content;
        margin: auto;
        justify-content: space-between;
    }
    
    .icons-row{
        display: flex;
        justify-content: center;
        gap: 0.3rem;
        margin-top: 0.3rem;
    }
    
    .adobe-logo{
        width: 30%;
    }
    
    .tech-title{
        color: #f9f9f9;
        font-size: 1rem;
        margin: auto auto 0;
        text-align: center;
        background-color: #171718;
        padding: 0.6rem;
        border-radius: 0.9rem;
        width: calc(90% + 0.8rem);
        height: fit-content;
    }
    
    .landscape-container{
        margin-top: 0.5rem;
        height: 63%;
        width: 100%;
        border-radius: 0.9rem;
        overflow: hidden;
    }
    
    .landscape-img{
        height: 100%;
        width: 100%;
        object-fit: cover;
    }

    .timelapse-card{
        padding: 0
    }

    .timelapse-video{
        object-fit: cover;
    }
    
    .about-title-row{
        display: flex;
    }
    
    .about-edit-button{
        margin-left: auto;
        background-color: transparent;
        border: none;
        color: #ccc;
        font-size: 1.5rem;
    }
    
    .about-edit-button:hover{
        color: #fff;
    }
    
    .edit-about-form{
        display: flex;
        flex-direction: column;
    }
    
    .edit-about-form label{
        color: #fff;
        text-align: left;
        font-size: 0.9rem;
        font-weight: 600;
    }
    
    .edit-about-input{
        margin-bottom: 0.5rem;
    }
    
    #edit-about-title-input{
        margin-bottom: 0.5rem;
    }
    
    .submit-about-general{
        margin-top: auto;
    }
}