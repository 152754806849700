.inner-dots-container{
    display: flex;
    gap: 0.5rem;
    align-items: center;
    margin: auto;
    width: fit-content;
    min-height: 1rem;
}

.dot{
    height: 0.5rem;
    width: 0.5rem;
    background-color: #999;
    border-radius: 50%;
    transition: 150ms ease-out all
}

.selected-dot{
    height: 0.9rem;
    width: 0.9rem;
    background-color: #fff;
}
