.welcome-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: transparent;
    padding: 3rem;
    z-index: 100;
    position: absolute;
    width: 100%;
}

.home-link{
    text-decoration: none;
    display: flex;
    align-items: center;
}

.profile-picture-container{
    height: 4rem;
    width: 4rem;
    aspect-ratio: 1;
    border-radius: 50%;
    overflow: hidden;
}

.profile-picture{
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.name-title{
    color: #fff;
    margin: 0 0 0 1rem;
    font-size: 1.5rem;
    text-shadow: 0 0 5px black;
    font-weight: 100;
}

.subtitle{
    color: #fff;
    margin: 0 2rem 0 0;
    font-size: 1.5rem;
    text-shadow: 0 0 5px black;
    font-weight: 100;
}

.exit-btn, .messages-btn{
    background-color: transparent;
    color: #ddd;
    border: none;
    margin: 0 0.5rem;
    border: 1px solid #ddd;
    padding: 0.3rem 0.4rem;
    border-radius: 0.5rem;
    text-shadow: 0 0 5px black;
    font-weight: 100;
}

.exit-btn:hover, .messages-btn:hover{
    color: #fff;
    border: 1px solid #fff;
}

@media (max-width: 768px) {

    .welcome-container{
        display: flex;
        align-items: center;
        background-color: transparent;
        padding: 1.5rem 1.5rem;
        z-index: 100;
        position: absolute;
        width: 100%;
    }

    .profile-picture-container{
        height: 2.5rem;
        width: 2.5rem;
        min-width: 2.5rem;
        margin-right: 0.5rem;
        aspect-ratio: 1/1;
        border-radius: 50%;
        overflow: hidden;
    }

    
    .profile-picture{
        height: 100%;
        width: 100%;
        object-fit: cover;
    }

    .name-title{
        margin-left: 0;
        font-size: 1rem ;
    }

    .subtitle{
        color: #fff;
        margin: 0 0 0 auto;
        font-size: 1rem;
        text-shadow: 0 0 5px black;
        font-weight: 100;   
    }

    .welcome-button-container{
        display: flex;
        justify-content: flex-end;
        width: 100%;
        margin: 0 0 0 auto;
    }

    .exit-btn, .messages-btn{
        background-color: transparent;
        color: #ddd;
        border: none;
        margin: 0 0.2rem;
        border: 1px solid #ddd;
        padding: 0.3rem;
        border-radius: 0.4rem;
        text-shadow: 0 0 5px black;
        font-weight: 100;
        font-size: 10px;
    }
    
    .exit-btn:hover, .messages-btn:hover{
        color: #fff;
        border: 1px solid #fff;
    }
}