.loading-spinner-container{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100%;
    position: absolute;
    background-color: #000c;
    z-index: 5000;
    backdrop-filter: blur(0.25rem) grayscale(0.5);
}

.loading-spinner{
    background-color: #da9401;
}