.content-displayer-container{
    position: relative;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 7rem;
    background: radial-gradient(#252527 0%, #171718 70%);
}

.section-btn-container{
    padding: 2rem 0 0 0;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    width: '70vh';
}

.section-row{
    display: flex;
    margin: 0;
    width: 100%;
    justify-content: space-between;
}

.section-btn{
    background: transparent;
    border: none;
    color: #bbb;
    font-size: 3vh; 
    font-weight: 600;
    letter-spacing: -1px;
    width: calc(70vh / 5);
    padding: 0;

}

.section-btn:hover{
    color: #fff;
}

.active-section{
    color:#fff;
}

.add-content-btn{
    background-color: #da9401;
    border: none;
    border-radius: 0.5rem;
    padding: 0.5rem 3rem;
    width: fit-content;
    margin: 0 auto;
}

.add-content-btn:hover{
    background-color: #fab431;
}

.add-content-icon{
    font-size: 2.5rem;
    text-align: center;
}

.section-selector{
    height: 0.3rem;
    width: 1.7rem;
    border-radius: 0.5rem;
    background-color: #da9401;
    position: relative;
    transition: 200ms ease-out all;
}

@media (max-width: 768px) {

    .content-displayer-container{
        position: relative;
        min-height: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin-top: 0;
        margin-bottom: 4rem;
        padding: 1rem;
        background: radial-gradient(#202020 0%, #171718 70%);
    }

    .section-btn-container{
        padding: 2rem 0 0 0;
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        width: 100%;
    }
    
    .section-row{
        display: flex;
        margin: 0;
        width: 100%;
        justify-content: space-between;
    }
    
    .section-btn{
        background: transparent;
        border: none;
        color: #ccc;
        font-size: 1rem; 
        font-weight: 600;
        letter-spacing: -1px;
        width: 20%;
        padding: 0;
    }
    
    .add-content-btn{
        background-color: #da9401;
        border: none;
        border-radius: 0.5rem;
        padding: 0.5rem 3rem;
        width: fit-content;
        margin: 0 auto;
    }
    
    .add-content-btn:hover{
        background-color: #fab431;
    }
    
    .add-content-icon{
        font-size: 2.5rem;
        text-align: center;
    }
    
    .section-selector{
        height: 0.2rem;
        width: 1.7rem;
        border-radius: 0.5rem;
        background-color: #da9401;
        position: relative;
        transition: 200ms ease-out all;
    }

}