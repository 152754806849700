.modal-tools-container{
    background-color: #444;
    margin: 0 0 0 2rem;
    display: flex;
    flex-direction: column;
    width: 5rem;
    align-items: center;
    justify-content: space-around;
}

.modal-tool-btn{
    background-color: transparent;
    border: none;
}

.modal-tool-icon{
    color: #aaa;
    font-size: 1.5rem;
}

.modal-tool-icon:hover{
    color: #fff;
}


@media (max-width: 768px) {
    .modal-tools-container{
        background-color: #444;
        margin: 0.5rem 0 0 0 ;
        padding: 1rem 0;
        display: flex;
        flex-direction: row;
        width: 100%;
        align-items: center;
        justify-content: space-around;
    }
    
    .modal-tool-btn{
        background-color: transparent;
        border: none;
    }
    
    .modal-tool-icon{
        color: #aaa;
        font-size: 1.5rem;
    }
    
    .modal-tool-icon:hover{
        color: #fff;
    }
    

}