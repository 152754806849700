.login-container{
    min-height: 100vh;
    background-color: #171717;
    padding: 2rem 20%;
    display: flex;
    font-family: Karla;
}

.login-title{
    color: #fff;
    font-size: 2rem;
    margin: 2rem;
    font-weight: 700;
}

.login-card{
    display: flex;
    border-radius: 1rem;
    max-width: 80%;
    margin: auto;
    box-shadow: 0 0 4px #000;
}

.login-form-container{
    background-color: #090909;
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
    width: 55%;
    padding: 0.8rem 2.5rem;
}

.login-form{
    display: flex;
    flex-direction: column;
}

.form-label{
    color: #fff;
    align-self: flex-start;
    margin-bottom: 0;
}

#email-input, #password-input{
    border-radius: 0.5rem;
    margin-bottom: 0.5rem;
    height: 2rem;
    background-color: transparent;
    border: 1px solid #444;
    color: #fff;
    padding-left: 0.3rem;
}


#email-input:focus, #password-input:focus{
    outline: 1px solid #da9401;
    border: none;
    background-color: transparent;
    color: #fff;
}

.login-btn{
    margin: 0.7rem 0;
    border: none;
}

.registered-tag{
    padding: 1rem;
    font-size: 1.1rem;
    font-weight: 100;
    color: #43b443;
}

.wrong-tag{
    padding: 1rem;
    font-size: 1.1rem;
    font-weight: 100;
    color: #c45353;
}

.enter-btn{
    background-color: #da9401;
    height: 2rem;
    font-weight: 600;
    color: #000;
    border-radius: 0.5rem;
}

.enter-btn:hover{
    background-color: #fab421;
}

.login-img-container{
    width: 45%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.login-img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
}

@media (max-width: 768px) {
    .login-container{
        min-height: 100vh;
        background-color: #171717;
        padding: 2rem 1rem;
        display: flex;
        font-family: Karla;
    }
    
    .login-card{
        display: flex;
        flex-direction: column;
        border-radius: 1rem;
        max-width: 100%;
        width: 100%;
        margin: auto;
        box-shadow: 0 0 4px #000;
    }
    
    .login-title{
        color: #fff;
        font-size: 1.5rem;
        margin: 1rem;
        font-weight: 700;
    }

    .login-form-container{
        background-color: #090909;
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;
        border-bottom-left-radius: 0;
        width: 100%;
        padding: 0.8rem 2.5rem;
    }
    
    .login-form{
        display: flex;
        flex-direction: column;
    }
    
    .form-label{
        color: #fff;
        align-self: flex-start;
        margin-bottom: 0;
        font-size: 0.9rem;
    }
    
    #email-input, #password-input{
        border-radius: 0.5rem;
        margin-bottom: 0.5rem;
        height: 2rem;
        background-color: transparent;
        border: 1px solid #444;
        color: #fff;
        padding-left: 0.3rem;
        font-size: 0.9rem;
    }
    
    
    #email-input:focus, #password-input:focus{
        outline: 1px solid #da9401;
        border: none;
        background-color: transparent;
        color: #fff;
    }
    
    .login-btn{
        margin: 0.7rem 0;
        border: none;
    }
    
    .registered-tag{
        padding: 1rem;
        font-size: 1.1rem;
        font-weight: 100;
        color: #43b443;
    }
    
    .wrong-tag{
        padding: 1rem;
        font-size: 1.1rem;
        font-weight: 100;
        color: #c45353;
    }
    
    .enter-btn{
        background-color: #da9401;
        height: 2rem;
        font-weight: 600;
        color: #000;
        border-radius: 0.5rem;
    }
    
    .enter-btn:hover{
        background-color: #fab421;
    }
    
    .login-img-container{
        width: 100%;
        height: 20rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .login-img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-top-right-radius: 0;
        border-bottom-right-radius: 1rem;
        border-bottom-left-radius: 1rem;
    }
}