.add-modal-container{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #0008;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    padding: 3%;
    z-index: 2000;
}

.add-item-info-container{
    overflow: auto;
    height: 100%;
    width: 60%;
    padding: 2rem 3rem;
    border-radius: 0.5rem;
    background-color: #222;
}



.add-modal-text-container{
    background-color: #222;
    margin: 0;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    width: 30rem;
}

.add-item-form{
    display: flex;
    flex-direction: column;
    min-height: 80%;
}

.add-input-label{
    color: #fff;
    align-self: flex-start;
    margin-bottom: 0.2rem;
    font-weight: 500;
}

.add-mode-title{
    color: #da9401;
    font-weight: 600;
    font-size: 1.5rem;
    margin-bottom: 2rem;
}

.text-input, input::file-selector-button{
    background-color: transparent;
    border: 1px solid #444;
    color: #fff;
    padding: 0.3rem;
    border-radius: 0.5rem;
}

input, textarea{
    margin-bottom: 1.5rem;
}

input::file-selector-button{
    background-color: #da9401;
    color: #fff;
    cursor: pointer;
}

input[type='file']{
    background-color: #aaaaaa;
    border-radius: 0.5rem;
    width: fit-content;
    padding-right: 1rem;
}

.text-input:focus{
    outline: 1px solid #da9401;
    border: none;
    background-color: transparent;
    color: #fff;
}

.add-submit, .hide-content-btn, .show-content-btn{
    color: #000;
    border: none;
    border-radius: 0.5rem;
    background-color: #da9401;
    height: 2rem;
    font-weight: 600;
    margin-top: auto;
    width: fit-content;
    padding: 0 1rem;
    margin: auto auto 0;
}


.add-submit{
    background-color: #da9401;
}

.add-submit:hover{
    background-color: #fab421;
}

.hide-content-btn{
    background-color: #444;
}

.show-content-btn{
    background-color: rgb(0, 100, 30);
}

.hidden-show-text{
    color: #fff;
    font-size: 0.8rem;
    margin-bottom: 0.3rem;
}

.hide-input-label{
    color: #fff;
}

.add-item-info-container::-webkit-scrollbar {
    width: 0.5rem;
    z-index: 10;
}
  
.add-item-info-container::-webkit-scrollbar-track {
    background-color: #171717
}
  
.add-item-info-container::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #444;
    box-shadow: inset 0 0 3px #000;
}
  
.add-item-info-container::-webkit-scrollbar-thumb:hover {
    background-color: #666;
}


.add-modal-close-btn{
    background: none;
    position: absolute;
    right: 0;
    top: 0;
    border: none;
}

.modal-close-icon{
    color: #555;
    font-size: 2.5rem;
}

@media (max-width: 768px) { 

    .add-modal-container{
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: #0008;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 3%;
        z-index: 2000;
    }
    
    .add-item-info-container{
        width: calc(100vw - 3rem);
        max-width: none;
        min-height: calc(100vw - 3rem);
        overflow: auto;
        height: 100%;
        padding: 1.5rem;
        border-radius: 0.5rem;
        background-color: #222;
    }
    
    
    .add-modal-text-container{
        background-color: #222;
        margin: 0;
        display: flex;
        flex-direction: column;
        padding: 1rem;
        border-top-right-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
        width: 30rem;
    }
    
    .add-item-form{
        display: flex;
        flex-direction: column;
        min-height: 80%;
    }
    
    .add-input-label{
        color: #fff;
        align-self: flex-start;
        margin-bottom: 0.2rem;
        font-weight: 500;
    }
    
    .add-mode-title{
        color: #da9401;
        font-weight: 600;
        font-size: 1.5rem;
        margin: 1.5rem 0; 
    }
    
    .text-input, input::file-selector-button{
        background-color: transparent;
        border: 1px solid #444;
        color: #fff;
        padding: 0.3rem;
        border-radius: 0.5rem;
    }
    
    input, textarea{
        margin-bottom: 1.5rem;
        font-size: 0.8rem;
    }
    
    input::file-selector-button{
        background-color: #da9401;
        color: #fff;
        cursor: pointer;
    }
    
    input[type='file']{
        background-color: #aaaaaa;
        border-radius: 0.5rem;
        width: fit-content;
        padding-right: 1rem;
    }
    
    .text-input:focus{
        outline: 1px solid #da9401;
        border: none;
        background-color: transparent;
        color: #fff;
    }
    
    .add-submit, .hide-content-btn, .show-content-btn{
        color: #000;
        border: none;
        border-radius: 0.5rem;
        background-color: #da9401;
        height: 2rem;
        font-weight: 600;
        width: fit-content;
        padding: 0 1rem;
        margin: auto auto 0;
    }
    
    .add-modal-close-btn{
        background-color: #0003;
        margin-left: auto;
        border: none;
        border-radius: 0.5rem;
        position: absolute;
        right: 0;
        z-index: 5000;
        display: block;
        padding: 0;
    }
    
    .modal-close-icon{
        color: #aaa;
        font-size: 2.5rem;
    }

    .add-submit{
        background-color: #da9401;
    }
    
    .add-submit:hover{
        background-color: #fab421;
    }
    
    .hide-content-btn{
        background-color: #444;
    }
    
    .show-content-btn{
        background-color: rgb(0, 100, 30);
    }
    
    .hidden-show-text{
        color: #fff;
        font-size: 0.8rem;
        margin-bottom: 0.3rem;
    }
    
    .hide-input-label{
        color: #fff;
    }

    .add-item-info-container::-webkit-scrollbar {
        display: none;
    }
      
    .add-item-info-container::-webkit-scrollbar-track {
        background-color: #171717
    }
      
    .add-item-info-container::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #444;
        box-shadow: inset 0 0 3px #000;
    }
      
    .add-item-info-container::-webkit-scrollbar-thumb:hover {
        background-color: #666;
    }
}