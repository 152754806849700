.footer-outer-container{
    background-color: #030303;
    z-index: 10;
    position: relative;
}

.footer-container{
    height: 15rem;
    background-color: #030303;
    display: flex;
    justify-content: space-around;
    align-items:first baseline;
    padding: 5rem 20%;
    color: #ccc;
    font-size: 1.2rem;
}

.footer-title{
    font-size: 1.6rem;
    font-weight: 500;
}

.link-container{
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.net-link{
    text-decoration: none;
    color: #aaa;
    font-weight: 400;
    font-size: 1.1rem;
}

.net-link:hover{
    color: #fff;
}

.dev-link{
    color: #999;
}

.game-btn{
    margin-top: -1rem;
    position: absolute;
    z-index: 8;
    background-color: #da9401;
    border: none;
    padding: 1rem;
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
    transition: all 200ms;
}

.game-btn svg{
    font-size: 2rem;
}

.game-btn:hover{
    margin-top: -3.2rem
}

@media (max-width: 768px) { 

    .game-btn{
        display: none;
    }

    .footer-container{
        height: fit-content;
        background-color: #030303;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: first baseline;
        padding: 2rem;
        color: #aaa;
    }
    
    .link-container{
        height: fit-content;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 1.4rem;
        display: block;
    }

    .footer-title{
        font-size: 1.2rem;
        font-weight: 500;
        margin-bottom: 0;
        text-align: center;
    }
    
    .net-link{
        text-decoration: none;
        color: #fff;
        font-weight: 400;
        font-size: 1rem;
        text-align: center;
        display: flex;
        flex-direction: column;
        margin-bottom: 0.5rem;
    }
    
    .net-link:hover{
        color: #fff;
    }
}